.about 
   padding: 0 0 32px
   position: relative
   display: block

   @media (min-width: 768px)
      padding: 0 0 80px

.list 
   display: flex
   align-items: center
   justify-content: center
   flex-direction: column

   @media (min-width: 768px)
      flex-direction: row

.list__item
   border-radius: 15px
   color: #141416
   position: relative
   display: inline-block
   margin: 0 25px
   transition: .5s all
   margin-bottom: 20px

   &::before
      content: ''
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0
      z-index: 1
      background-color: transparent
      transition: all ease-in .3s
      border-radius: inherit
   
   &:hover
      .list__item__content
         border: 2px solid #4F54F6
         left: 8px
         bottom: 8px

      &::before
         background-color: #4F54F6

   @media (min-width: 768px)
      max-width: 300px
      margin-bottom: 0

   // &:hover
   //    border: 2px solid #4F54F6
   //    &::after
   //       background: #4F54F6
   //       transform: translate(2px, 0px)
   //       border: 2px solid #4F54F6
   // &::after
   //    content: ''
   //    width: 100%
   //    height: 100%
   //    background: red
   //    position: absolute
   //    top: 10px
   //    right: 10px
   //    border: 2px solid transparent
   //    border-radius: 15px
   //    background: #fff
   //    z-index: -1
   //    trasnform: translate(8px, -13px)
   //    transition: .5s all
   

.list__item__content
   position: relative
   z-index: 2
   padding: 35px
   border: 2px dashed #141416
   border-radius: 15px
   transition: all ease-in .3s
   background-attachment: fixed
   left: 0
   bottom: 0

   h3
      font-family: 'Poppins'
      font-style: normal
      font-weight: 500
      font-size: 16px
      line-height: 24px
   
   p
      font-family: 'Poppins'
      font-style: normal
      font-weight: 400
      font-size: 12px
      line-height: 20px
      margin-top: 16px