.hero-banner
   padding: 110px 0 120px

   &.blue-blur
      background-image: url(./home-banner-blur-01.png)
      background-repeat: no-repeat
      background-position: 95% 80%

   &.green-blur
      background-image: url(./home-banner-blur-03.png)
      background-repeat: no-repeat
      background-position: 80% 80%

   .container
      position: relative

.hero-banner__prelude
.hero-banner__title
   font-family: 'DM Sans'
   font-style: normal
   font-weight: 700
   font-size: 48px
   line-height: 56px
   letter-spacing: -0.02em
   color: #0D0C22
   strong
      color: #404EED
.hero-banner__content
   font-family: 'Poppins'
   font-style: normal
   font-weight: 600
   font-size: 14px
   line-height: 24px
   color: #4F54F6
   margin-top: 34px
   max-width: 410px
   span
      color: #000
      font-weight: 400
.hero-banner__header
   @media (min-width: 768px)
      max-width: 590px
      margin-top: 60px
.hero-banner__form
   margin-top: 24px
   position: relative
   max-width: 300px
   z-index: 1
   &::after
      content: ''
      width: 100%
      height: 48px
      background: red
      position: absolute
      top: 10px
      right: 10px
      border: 2px solid #4F54F6
      border-radius: 90px
      background: #4F54F6
      transform: translate(6px,-4px)
      z-index: -1
   input
      border: 2px solid #4F54F6
      background: transparent
      // filter: drop-shadow(-5px 6px 0px #4F54F6)
      border-radius: 90px
      color: #0D0C22
      font-family: 'Poppins'
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 24px
      padding: 10px 12px
      background: #fff
      width: 100%
.hero-banner__form-submit
   width: 32px
   height: 32px
   background-color: #4f54f6
   background-image: url('./arrow.svg')
   background-repeat: no-repeat
   background-position: center
   border-radius: 100%
   position: absolute
   top: 8px
   right: 8px
.hero-banner__graphism-head
   padding: 27px 31px
   width: 100%
   position: relative
   z-index: 2
   &::after
      content: ''
      background: linear-gradient(0, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, 0) 100%)

      width: 100%
      height: 200px
      position: absolute
      top: 0
      left: 0
      z-index: -1
.hero-banner__graphism
   // background-image: url('./graphism-bg.svg')
   // background-position: -10px -49px
   // background-repeat: no-repeat
   // background-size: 111%
   padding: 50px 0 0
   @media (min-width: 768px)
      padding: 5px 0 0 100px
.hero-banner__graphism-list
   display: flex
   flex-direction: row
.hero-banner__graphism-item
   width: 343px
   max-width: 100%
   border-radius: 20px
   background-size: cover
   background-position: center
   background-repeat: no-repeat
   overflow: hidden
   min-height: 535px
   position: relative
   display: flex
   align-items: flex-end
   &:not(:last-child)
      margin-right: 30px
.hero-banner__graphism-item--middle
   max-width: 235px
   border-radius: 20px
   overflow: hidden
   & .hero-banner__graphism-head
      padding: 19px 19px 27px
.hero-banner__graphism-image
   height: 310px
   width: 100%
   object-fit: cover
.hero-banner__graphism-title
   font-family: 'DM Sans'
   font-style: normal
   font-weight: 700
   font-size: 27px
   line-height: 37px
   letter-spacing: -0.01em
   color:  #FCFCFD
   // margin-bottom: 17px
   display: block
.hero-banner__graphism-content
   font-family: 'Poppins'
   font-style: normal
   font-weight: 500
   font-size: 16px
   line-height: 24px
   color: #FCFCFD
   display: block
   &--small
      font-size: 11px
      line-height: 24px
      font-weight: 600
.hero-banner__graphism-value
   font-family: 'DM Sans'
   background: rgba(255, 255, 255, .3)
   color: #FCFCFD
   font-weight: 700
   font-size: 11px
   line-height: 16px
   padding: 2px 9px
   display: inline-block
   border-radius: 7px
.hero-banner__graphism-group
   display: flex
   align-items: center
   margin-top: 6px
   & span:nth-child(1)
      margin-right: 10px
.hero-banner__holder
   display: flex
   flex-direction: column
   @media (min-width: 768px)
      flex-direction: row
      justify-content: space-between
.hero-banner__fixed
   background: #6166F8
   color: #fff
   border-radius: 20px 20px 0px 0px
   padding: 19px
   position: fixed
   top: 0
   right: 0
   transform: translateX(62px) translateY(220px) rotate(270deg)
.hero-banner__fixed-title