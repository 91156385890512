@import ../../styles/helpers

.counter
  position: relative
  display: inline-block
  margin-top: 90px

.counterTitle
  display: inline-block
  padding: 2px 15px 4px
  margin-bottom: 10px
  position: relative
  &::before,
  &::after
    content: ''
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    transform: skew(-30deg)
  &::after
    background-color: $neutrals2
  &::before
    background-color: #4F54F6
    left: -8px
    top: 4px
  p
    position: relative
    z-index: 2
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 20px
    color: #FFF
    letter-spacing: -0.045em
    +dm-sans

.counterTagList
  display: flex
  align-items: center

.tagTitle
  +dm-mono
  font-style: normal
  font-weight: 500
  font-size: 40px
  line-height: 48px
  margin-right: 16px
  display: inline-block
  vertical-align: middle

.tagIcon
  display: inline-block
  vertical-align: middle
  img
    width: 16px
    height: 16px

.starDetail
  margin: 0 26px 40px
  width: 10px
  height: 10px